<template>
  <section>
    <div class="row">
      <div class="col-12 grid-margin stretch-card contatos">
        <div class="card card-statistics">
          <div class="row">
            <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                  <i class="mdi mdi-account-box-multiple text-info mr-0 mr-sm-4 icon-lg"></i>
                  <div class="wrapper text-center text-sm-left">
                    <p class="card-text mb-0">Contatos</p>
                    <div class="fluid-container">
                    <h3 class="mb-0 font-weight-medium">{{ totalRows | numberFilter }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                  <i class="mdi mdi mdi-phone-classic text-success mr-0 mr-sm-4 icon-lg"></i>
                  <div class="wrapper text-center text-sm-left">
                    <p class="card-text mb-0">Telefones</p>
                    <div class="fluid-container">
                    <h3 class="mb-0 font-weight-medium">{{ totalContatos.telefones | numberFilter }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-col col-xl-4 col-lg-6 col-md-6 col-6 border-right">
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                  <i class="mdi mdi-mail-ru text-warning mr-0 mr-sm-4 icon-lg"></i>
                  <div class="wrapper text-center text-sm-left">
                    <p class="card-text mb-0">Emails</p>
                    <div class="fluid-container">
                    <h3 class="mb-0 font-weight-medium">{{ totalContatos.emails | numberFilter }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Contatos</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>
              <b-col>
                <b-button-toolbar
                  aria-label="Barra de Ferramentas"
                  class="d-flex flex-row-reverse"
                >
                  <b-button-group class="mx-1">
                    <b-dropdown text="Exportar" variant="primary">
                      <template #button-content>
                        <b-spinner
                          v-if="exporting"
                          small
                          type="grow"
                        ></b-spinner>
                        <b-icon
                          v-else
                          icon="download"
                          aria-hidden="true"
                        ></b-icon>
                        Exportar
                      </template>
                      <b-dropdown-item @click="download('ods')"
                        ><i class="fa fa-file-excel-o"></i> Open Document Format
                        (.ODS)
                      </b-dropdown-item>
                      <b-dropdown-item @click="download('xlsx')">
                        <i class="fa fa-file-excel-o"></i> Microsoft Excel
                        (.XLSX)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('csv')">
                        <i class="fa fa-file-excel-o"></i> Comma-Separated
                        Values (.CSV)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('pdf')">
                        <i class="fa fa-file-pdf-o"></i> Portable Document
                        Format (.PDF)</b-dropdown-item
                      >
                    </b-dropdown>
                    <b-button
                      v-if="
                        $store.getters['auth/hasPermission']('Importar Base')
                      "
                      class="ml-1 border--solid"
                      variant="primary"
                      :to="{ name: 'ContatoImport' }"
                    >
                      <b-icon icon="upload" aria-hidden="true"></b-icon>
                      Importar Lote
                    </b-button>
                    <!-- <b-button>Criar Contato</b-button> -->
                  </b-button-group>

                  <b-button
                    v-if="
                      $store.getters['auth/hasPermission']('Editar Contatos')
                    "
                    variant="success"
                    :to="{
                      name: 'addContato',
                    }"
                  >
                    <i class="mdi mdi-plus"></i>
                    Novo Contato
                  </b-button>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-card-title>

          <empresa-filtros filtro-contato></empresa-filtros>

          <b-row v-if="selectedRows.length > 0">
            <!-- <b-col>
              <b-dropdown id="selected-rows-dropdown" variant="primary" class="my-2 h-100">
                <template #button-content>
                  Enviar <strong>{{selectedRows.length}}</strong> registros selecionados
                </template>
                <b-dropdown-item @click="addLeads"><i class="mdi mdi-phone"></i> Qualificação</b-dropdown-item>
              </b-dropdown>
            </b-col> -->
            <b-col>
              <b-button
                variant="primary"
                class="my-2 h-100"
                v-b-modal.leads-modal
                >Enviar <strong>{{ selectedRows.length }}</strong> contatos para
                qualificação</b-button
              >
            </b-col>
          </b-row>

          <b-table
            ref="selectableTable"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="empresaFiltros"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :selectable="true"
            @row-selected="onRowSelected"
            select-mode="multi"
            selected-variant="dark"
            responsive
            :busy="loadingTable"
          >
            <template #table-busy>
              <div class="text-center text-white my-2 loadding-white-mode">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #head(selected)>
              <b-form-checkbox size="md" @change="selectAllRows">
              </b-form-checkbox>
            </template>

            <template #cell(selected)="row">
              <b-form-checkbox
                size="md"
                :checked="row.rowSelected"
                @change="selectRow(row)"
              >
              </b-form-checkbox>
            </template>

            <template #cell(nome)="row">
              <span class="text-wrap name__strong">
                {{ row.item.nome }}
              </span>
            </template>

            <template #cell(razao_social)="row">
              <span class="text-wrap">
                <router-link
                  v-if="row.item.empresa"
                  :to="{
                    name: 'EmpresaView',
                    params: { id: row.item.empresa.id },
                  }"
                >
                  <template v-if="row.item.empresa.nome_fantasia">
                    {{ row.item.empresa.nome_fantasia }}
                  </template>
                  <template v-else-if="row.item.empresa.empresa_base">
                    {{ row.item.empresa.empresa_base.razao_social }}
                  </template>
                  <template v-else>
                    {{ row.item.empresa_cnpj | cnpjFilter }}
                  </template>
                </router-link>
                <template v-else-if="row.item.cnpj">
                  {{ row.item.cnpj | cnpjFilter }}
                </template>
                <template v-else-if="row.item.empresa_nome">
                  {{ row.item.empresa_nome }}
                </template>
              </span>
            </template>

            <template #cell(contatos)="row">
              <b-button-group>
                <b-button
                  :id="'telefone-popover-' + row.item.id"
                  variant="primary"
                >
                  <i class="mdi mdi-phone"></i>
                </b-button>

                <b-button
                  :id="'email-popover-' + row.item.id"
                  variant="primary"
                  class="ml-1 border--solid"
                >
                  <i class="mdi mdi-email"></i>
                </b-button>
              </b-button-group>

              <b-popover
                :target="'telefone-popover-' + row.item.id"
                triggers="hover"
                placement="left"
                variant="info"
              >
                <template #title>Telefones </template>

                <b-list-group flush>
                  <b-list-group-item
                    variant="info"
                    v-for="telefone in row.item.contato_telefones"
                    :key="telefone.id"
                    style="
                      width: 200px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ telefone.telefone }}

                    <i
                      v-if="telefone.valido"
                      class="icon-check text-success"
                      v-b-tooltip.hover
                      title="Válido"
                    ></i>

                    <i
                      v-else-if="telefone.valido === 0"
                      class="icon-info text-danger"
                      v-b-tooltip.hover
                      title="Inválido"
                    ></i>

                    <i
                      v-else
                      class="icon-info text-warning"
                      v-b-tooltip.hover
                      title="Validação Pendente"
                    ></i>
                  </b-list-group-item>
                </b-list-group>
              </b-popover>

              <b-popover
                :target="'email-popover-' + row.item.id"
                triggers="hover "
                placement="bottom"
                variant="info"
                :custom-class="
                  row.item.contato_emails.length > 0 ? 'my-popover-class' : ''
                "
              >
                <template #title>Emails</template>

                <b-list-group flush>
                  <b-list-group-item
                    variant="info"
                    v-for="email in row.item.contato_emails"
                    :key="email.id"
                    class=""
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ email.email }}

                    <i
                      v-if="email.valido"
                      class="icon-check text-success"
                      v-b-tooltip.hover
                      title="Válido"
                    ></i>

                    <i
                      v-else-if="email.valido === 0"
                      class="icon-info text-danger"
                      v-b-tooltip.hover
                      title="Inválido"
                    ></i>

                    <i
                      v-else
                      class="icon-info text-warning"
                      v-b-tooltip.hover
                      title="Validação Pendente"
                    ></i>

                    <!-- <b-badge v-if="email.valido" variant="success"
                      >Válido</b-badge
                    >
                    <b-badge v-else-if="email.valido === 0" variant="danger"
                      >Inválido</b-badge
                    >
                    <b-badge v-else variant="dark">Validação Pendente</b-badge> -->
                  </b-list-group-item>
                </b-list-group>
              </b-popover>
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                class="mr-1"
                title="Visualizar"
                :to="{ name: 'ContatoView', params: { id: row.item.id } }"
              >
                Visualizar
              </b-button>

              <b-dropdown
                class="btn dotted-btn-transparent pb-0"
                text="Dropdown"
              >
                <template slot="button-content">
                  <i class="mdi mdi-dots-vertical"></i>
                </template>

                <b-dropdown-item
                  :to="{ name: 'EditContato', params: { id: row.item.id } }"
                >
                  <i class="mdi mdi-pencil mr-2" style="font-size: 1rem"></i>
                  Editar contato
                </b-dropdown-item>

                <b-dropdown-item @click="removeContact(row)">
                  <i class="mdi mdi-delete mr-2" style="font-size: 1rem"></i>
                  Remover contato
                </b-dropdown-item>
              </b-dropdown>

              <!-- <b-button
                variant="outline-primary"
                class="mr-1"
                title="Editar Contato"
                :to="{ name: 'EditContato', params: { id: row.item.id } }"
              >
                <i class="mdi mdi-pencil"></i>
              </b-button>

              <b-button
                variant="outline-danger"
                class="mr-1"
                title="Remover Contato"
                @click="removeContact(row)"
              >
                <i class="mdi mdi-delete"></i>
              </b-button> -->
            </template>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <b-modal
      id="leads-modal"
      centered
      size="md"
      header-text-variant="light"
      no-close-on-backdrop
      header-class="headerModal"
      hide-footer
    >
      <template #modal-title>
        Enviar
        <span class="text-primary">{{ selectedRows.length }} contatos </span>
        para qualificação
      </template>

      <form class="forms-sample" @submit.stop.prevent="addLeads">
        <b-form-group label="Projeto">
          <projeto-select></projeto-select>
        </b-form-group>
        <b-form-group label="Tags">
          <tag-input v-model="tags"></tag-input>
        </b-form-group>
        <div class="d-flex">
          <b-button
            type="submit"
            variant="success"
            class="mr-2"
            :disabled="loading"
          >
            <template v-if="loading">
              <b-spinner small type="grow"></b-spinner>
              Processando...
            </template>
            <template v-else> Confirmar </template>
          </b-button>
        </div>
      </form>
    </b-modal>

    <!-- <b-modal
      id="whatsapp-modal"
      centered
      size="md"
      header-text-variant="light"
      no-close-on-backdrop
      header-class="headerModal"
      hide-footer
    >

    
      <template #modal-title>
        <h4>WhatsApp Sender</h4>

        Enviar mensagem para
        <span class="text-primary">{{ selectedRows.length }} contatos </span>
        via WhatsApp
      </template>



      





      <form class="forms-sample" @submit.stop.prevent="addLeads">
        <b-form-group label="Projeto">
          <projeto-select></projeto-select>
        </b-form-group>
        <b-form-group label="Tags">
          <tag-input v-model="tags"></tag-input>
        </b-form-group>
        <div class="d-flex">
          <b-button
            type="submit"
            variant="success"
            class="mr-2"
            :disabled="loading"
          >
            <template v-if="loading">
              <b-spinner small type="grow"></b-spinner>
              Processando...
            </template>
            <template v-else> Confirmar </template>
          </b-button>
        </div>
      </form>



    </b-modal>
 -->
  </section>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import EmpresaFiltros from "@/components/EmpresaFiltros.vue";
import ProjetoSelect from "@/components/ProjetoSelect.vue";
import TagInput from "@/components/TagInput.vue";
import ContatoService from "../../services/contato.service";
import LeadService from "../../services/lead.service";

export default {
  name: "Contatos",
  components: {
    EmpresaFiltros,
    ProjetoSelect,
    TagInput,
  },
  data() {
    return {
      fields: [
        "selected",
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "cargo",
          sortable: false,
          label: "Cargo",
        },
        {
          key: "razao_social",
          sortable: false,
          label: "Empresa",
        },
        {
          key: "contatos",
          sortable: false,
          label: "Contatos",
        },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      totalContatos: 
        {
          "telefones": 0,
          "emails": 0,
          "enderecos": 0
        },
      currentPage: 1,
      perPage: 20,
      sortBy: "nome",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      exporting: false,
      selectedRows: [],
      loading: false,
      tags: [],

      message: "",
      selected: null,
      options: [],
    };
  },
  computed: {
    ...mapState(["empresaFiltros", "projetoSelecionado"]),
    ...mapGetters(["empresaFiltrosQuery"]),
  },
  mounted() {
    this.updateTotalContatos();
  },
  methods: {
    search(ctx) {
      this.ctx = ctx;
      let params = {
        ...this.empresaFiltrosQuery,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = ContatoService.getAll(params);

      return promise
        .then((response) => {
          // console.log(response);
          this.loadingTable = false;
          const items = response.data.data;
          this.totalRows = response.data.total;
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    selectAllRows(checked) {
      if (checked) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    selectRow(row) {
      if (row.rowSelected) {
        row.unselectRow();
      } else {
        row.selectRow();
      }
    },
    onRowSelected(items) {
      this.selectedRows = items;
    },
    updateTotalContatos() {
      let params = {
        ...this.empresaFiltrosQuery,
      };
      // this.loadingTotal = true;
      ContatoService.totalContatos(params)
        .then((response) => {
          // this.loadingTotal = false;
          this.totalContatos = response.data;
        })
        .catch(() => {
          // this.loadingTotal = false;
          this.totalRows = null;
        });
    },
    download(format) {
      let params = this.empresaFiltrosQuery;
      this.exporting = true;
      const promise = ContatoService.download(format, params);
      return promise
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "contatos." + format;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .then(() => {
          this.exporting = false;
        });
    },
    addLeads() {
      if (!this.projetoSelecionado || !this.selectedRows) {
        return;
      }
      this.loading = true;
      let contato_ids = this.selectedRows.reduce((accumulator, item) => {
        accumulator.push(item.id);
        return accumulator;
      }, []);

      const promise = LeadService.saveOrUpdate({
        projeto_id: this.projetoSelecionado.id,
        contato_ids: contato_ids,
        tags: this.tags,
      });
      return promise
        .then((response) => {
          this.$bvToast.toast(response.data.message, {
            title: "Sucesso!",
            autoHideDelay: 1000,
            toaster: "b-toaster-bottom-left",
            variant: "success",
          });
          this.$bvModal.hide("leads-modal");
          this.$refs.selectableTable.clearSelected();
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },

    removeContact(row) {
      // console.log(row);

      this.$swal({
        title: row.item.nome,
        text: "Confirma a remoção desse Contato?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          ContatoService.delete(row.item.id)
            .then((res) => {
              this.$refs.selectableTable.refresh();

              this.$swal(
                "Removido!",
                `<p> <span class="text-capitalize font-weight-bold">${res.data.message}</span> </p> `,
                "success"
              );
            })

            .catch((erro) => {
              console.error(erro.message);
              this.makeToast("danger", "Error!", erro.message);
            });
        }
      });
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },

  watch: {
    empresaFiltrosQuery() {
      this.updateTotalContatos();
    },
    selected() {
      // console.log("mudou");
      this.message = this.selected;
    },
    selectedRows() {
      //console.log(this.selectedRows);
    },
  },
};
</script>



<style lang="scss" >
#sidebar-for-send-whatsapp {
  width: 40%;
  background: #191c24 !important;
  box-shadow: 0 0 35px -3px #000;
  border-top-left-radius: 6px;
  transition: 0.4s;

  textarea {
    height: 250px;
  }
}

.my-popover-class {
  /* background: #191c24 !important; */
  min-width: 350px;
  max-width: auto !important;
}
</style>


<style lang="scss" scoped>
.form-control:disabled,
.form-control[readonly] {
  background: transparent;
}
</style>
